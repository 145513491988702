import React, { useEffect, useRef } from 'react';
import { TypeAnimation } from 'react-type-animation';
import './index.css';
import { motion} from "framer-motion/dist/framer-motion"
import WaterWave from 'react-water-wave';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function Hero() {
    const pageVariants = {
        initial: {
          opacity: 0,
          y: "-10vh",
          scale: 0.8,
        },
        in: {
          opacity: 1,
          y: 0,
          scale: 1,
        },
        out: {
          opacity: 0,
          y: "100vh",
          scale: 1.2,
        },
    };
    
    const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.8,
    };
    return (
        // <div id="home" className="hero__container">
            <WaterWave
                id="home"
                className="hero__container"
                imageUrl={'https://i.ibb.co/n8hnR8c/background.jpg'}>
                    {
                        methods => (
                            <div className="parent__container">
                                <motion.div
                                    className="anim__container"
                                    initial="initial"
                                    animate="in"
                                    exit="out"
                                    variants={pageVariants}
                                    transition={pageTransition}>
                                        <img height={300} src='/images/stacked_logo.svg' alt='' />
                                    <TypeAnimation
                                        sequence={[
                                            'Testing HEF, AR, and AFFF Proportioner Systems',
                                            1000,
                                        ]}
                                        wrapper="h5"
                                        speed={30}
                                        style={{ padding: 0, margin: 0, fontSize: '1.2rem', color: '#24aaee'}}
                                        repeat={Infinity}
                                    />
                                    <h5>Annual Foam Proportioner Testing with <span className="color__green">Water</span><br /><span className="color__green">AR-FFF, AFFF <span style={{ color: '#fff' }}>&</span> HEF</span></h5>
                                    <div className="btn__container">
                                        <a className="btn btn__learn" href="#about">Learn More</a>
                                        <a className="btn btn__contact" href="#contact">Contact us</a>
                                    </div>
                                </motion.div>
                            </div>
                        )
                    }
                
            </WaterWave>
        // </div>
    )
}

export default Hero;
