import React from 'react'
import Dashboard from '../Dashboard'

function HomeDashboard() {
  return (
    <Dashboard>
        <div style={{ display: 'flex', flexDirection: 'column', height: '90%'}}>
            <div style={{ display: 'flex', marginTop: '1rem', gap: '3rem' }}>
                <div style={{ backgroundColor: '#202020', flex: 1, height: '100px', borderRadius: '20px' }}>

                </div>
                <div style={{ backgroundColor: '#202020', flex: 1, height: '100px', borderRadius: '20px' }}>

                </div>
                <div style={{ backgroundColor: '#202020', flex: 1, height: '100px', borderRadius: '20px' }}>

                </div>
                <div style={{ backgroundColor: '#202020', flex: 1, height: '100px', borderRadius: '20px' }}>

                </div>
                <div style={{ backgroundColor: '#202020', flex: 1, height: '100px', borderRadius: '20px' }}>

                </div>
            </div>
            <div style={{ display: 'flex', marginTop: '1rem', gap: '2rem' }}>
                <div style={{ backgroundColor: '#202020', flex: 1, height: '300px', borderRadius: '20px' }}>

                </div>
                <div style={{ backgroundColor: '#202020', flex: 1, height: '300px', borderRadius: '20px' }}>

                </div>
            </div>
            <div style={{ display: 'flex', marginTop: '1rem', gap: '2rem' }}>
                <div style={{ backgroundColor: '#202020', flex: 1, height: '300px', borderRadius: '20px' }}>

                </div>
            </div>
        </div>
    </Dashboard>
  )
}

export default HomeDashboard