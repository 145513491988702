import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import { BrowserRouter as Router } from 'react-router-dom';
import {
  BrowserRouter,
} from "react-router-dom";
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history'
//...

ReactDOM.render(
    <BrowserRouter>
      <Auth0ProviderWithHistory />
    </BrowserRouter>
    ,
    document.getElementById('root'),
  );