import React from 'react';
import SidePhoto from '../../images/side__photo.png';
import './index.css';

function Features() {
    return (
        <div id="features" className="features__container">
            <h2>Our <span className="color__blue">Company</span> Features</h2>
            <p className="content">We pride ourselves in giving our clients the best deals possible. Why not save our clients<br /> money, and the enviornment at the same time?</p>
            <div className="features__container__section">
                <div className="features__container__item">
                    <h3 className='color__blue'>We always give 100%</h3>
                    <p className="content__two">No matter the situation, or where your located. We gurantee we can save you time and money with our foam proportioner testing abilities.</p>
                    <div className="features__container__item__capablities">
                        <div className="capability__item">
                            <img src="../../images/dollar.svg" alt="Dollar" />
                            <div>
                                <h4 className='color__blue'>Cost Efficient</h4>
                                <p className='color__black'>Our process saves you significant amounts of money on foam concentrate.</p>
                            </div>
                        </div>
                        <div className="capability__item">
                            <img src="../../images/recycle.svg" alt="Recycle" />
                            <div>
                                <h4 className='color__blue'>Environmentally Friendly</h4>
                                <p className='color__black'>We only test with water. Meaning there are no environmental impacts, clean-up concerns, containment/disposal costs.</p>
                            </div>
                        </div>
                        <div className="capability__item">
                            <img src="../../images/test.svg" alt="testing kit" />
                            <div>
                                <h4 className='color__blue'>Thorough Testing</h4>
                                <p className='color__black'>We can test systems for substantial periods while only flowing water. Making sure everything is working correctly!</p>
                            </div>
                        </div>
                        <div className="capability__item">
                            <img src="../../images/tests.svg" alt="capabilty" />  
                            <div>
                                <h4 className='color__blue'>Variation Testing</h4>
                                <p className='color__black'>Our testing allows us to change the system operating parameters over a wide range of flow rates.</p>
                            </div>
                        </div>
                        <div className="capability__item">
                            <img src="../../images/checklist.svg" alt="checklist" />
                            <div>
                                <h4 className='color__blue'>Requirement Driven</h4>
                                <p className='color__black'>We meet all the requirements from the manufacturer for flushing systems with water.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="features__container__item">
                    <img className="side__image" src={SidePhoto} alt="Foam proportioner testing tools" />
                </div>
            </div>
        </div>
    )
}

export default Features;
