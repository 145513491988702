import React, { useContext, useEffect, useState } from 'react';
import './index.css';
import Burger from '../burger/Burger';
import hLogo from '../../images/logo_horizontal.svg';
import Menu from '@mui/material/Menu';
import AuthenticationButton  from '../buttons/Authenticate';
import MenuItem from '@mui/material/MenuItem';
import { NFPAContext } from '../../providers/NFPAProvider';
import { Link } from 'react-router-dom';

function Navigation() {
    const [ navbar, setNavbar ] = useState(false);
    const [ open, setOpen ] = useState(false);
    const [ openTwo, setOpenTwo] = useState(false);
    const [anchorLa, setAnchorLa] = useState(null);
    const { data } = useContext(NFPAContext)
    const [results, setResults] = useState([])

    const changeNaV = () => {
        if(window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false)
        }
    }

    function handleClick(event) {
        setAnchorLa(event.currentTarget)
        setOpenTwo(!open);
      }

    window.addEventListener('scroll', changeNaV);
    useEffect(() => {
        console.log(data)
    }, [data])

    useEffect(() => {

    }, [])

    return (
        <div className={navbar ? 'navigation__container active' : 'navigation__container'}>
            <div className="navigation__section">
                <a href="/"><img src={hLogo} className={navbar ? 'background__color__dark' : 'background__color__blue'} alt="Company Logo" /></a>
                <h3>Call us +833-600-3626</h3>
            </div>
            <div className="navigation__section">
                <a className="navigation__item" href="/#home">Home</a>
                <a className="navigation__item" href="/#services">Services</a>
                <a className="navigation__item" href="#features">Features</a>
                <a className="navigation__item" onClick={(e) => handleClick(e)}>NFPA</a>
                <a className="navigation__item" href="#contact">Contact</a>
                <div style={{ height: '30%', backgroundColor: 'lightgrey', width: '1px', margin: '.5rem'}} />
                <AuthenticationButton />
            </div>
            <Menu
                id="basic"
                anchorEl={anchorLa}
                open={openTwo}
                onClose={() => setOpenTwo(!openTwo)}
                disableScrollLock={true}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                {
                    data !== null ? data.map((item, i) => {
                        return <Link to={`/nfpa/${item.id}`} style={{ color: 'black'}}><MenuItem key={`item-${i}`}>{item.title}</MenuItem></Link>
                    }) : null
                }
            </Menu>
            {/* <Burger open={open} setOpen={setOpen} />
            <Menu open={open} setOpen={setOpen} /> */}
        </div>
    )
}

export default Navigation;
