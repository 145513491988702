import React, { useState } from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import RoomIcon from '@material-ui/icons/Room';
import axios from 'axios';
import './index.css';

function Contact() {
    const [ btnText, setBtn ] = useState('Submit');
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ message, setMessage ] = useState('');
    const [recaptchaResponse, setResponse] = useState('');


    const handleSubmit = async (e) => {
        e.preventDefault();
        const body = {
            sender: 'foamsolutions',
            gResponse: recaptchaResponse,
            name: name,
            email: email,
            phone: phone,
            message: message,
            emails: ['management@lovast.com', 'ps@fs131.com']
        };
        const r = await axios.post(`${process.env.REACT_APP_API_URL}/utils`, {
            name: name,
            email: email,
            phone: phone,
            message: message
        }).then(() => {
            setBtn('Form submitted!')
            setName('');
            setEmail('');
            setPhone('');
            setMessage('');
        }).catch((error) => {
            console.log(error)
            btnText('Error, reload page!');
        })
        console.log(r)
        // const res = await fetch('https://lovast.com/api/v1/comms/email', {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify(body),
        // });
    };

    return (
        <div id="contact" className="contact__container">
            <h2><span className="color__blue">Contact</span> us</h2>
            <p className="contact__container__context">The only bad question is the one never asked. We are happy to assist you<br />with any and all questions!</p>
            <div className="form__section">
                <div className="form__section__one">
                    <div className="icon__container">
                        <MailIcon className="icon__container__icon" />
                        <p>ps@fs131.com</p>
                    </div>
                    <div className="icon__container">
                        <PhoneIcon className="icon__container__icon" />
                        <p>+833-600-3626</p>
                    </div>
                    <div className="icon__container">
                        <RoomIcon className="icon__container__icon" />
                        <p>2251 Performance Parkway<br />Columbus, OH 43207<br /></p>
                    </div>
                </div>
                <form className="form__section__two" onSubmit={handleSubmit}>
                    <input style={{width: '100%'}} className="contact__input" name="name" placeholder="Name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    <div className="input__section">
                        <input className="contact__input width__fifty" name="email" placeholder="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <input className="contact__input width__fifty" name="phone" placeholder="Phone" type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                    <textarea className="contact__input" rows="7" name="message" placeholder="Type message here..." value={message} onChange={(e) => setMessage(e.target.value)} />
                    <input style={{ cursor: 'pointer' }} disabled={btnText === 'Form submitted!' ? true : false} className="contact__button" type="submit" value={btnText} />
                </form>
            </div>
        </div>
    )
}

export default Contact;
