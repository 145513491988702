import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios'

export const NFPAContext = createContext();

export const NFPAProvider = ({ children }) => {
    const [data, setData] = useState(null)
    const [isLoaded, setLoaded] = useState(null)

    async function getAllPages() {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/utils/pages/all`)
            console.log(res.data.r)
            setData(res.data.r)
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        getAllPages()
    },[])

    return (
        <NFPAContext.Provider value={{ data, setData, isLoaded }}>
            {children}
        </NFPAContext.Provider>
    )
}