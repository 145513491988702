import React from 'react';
import RoomIcon from '@material-ui/icons/Room';
import './index.css';

function Pin() {
    return (
        <div>
            <RoomIcon className="pin" />
            <h6 style={{fontSize: '1rem', color: 'lightblue'}}>Foam Solutions LLC.</h6>
        </div>
    )
}

export default Pin;
