import React, { useState, useEffect } from 'react';
import LoginButton from './Login';
import { useAuth0 } from '@auth0/auth0-react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';


export default function AuthenticationButton() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
    setOpen(!open);
  }

  const { isAuthenticated, logout, user } = useAuth0();

  function handleLogout() {
    setOpen(!open);
    logout({
        returnTo: process.env.REACT_APP_AUTH0_RETURN_URL
    })
  }
  useEffect(() => {
    console.log(user);
  }, [user])

  return isAuthenticated ? (
    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <img style={{ borderRadius: '50%', height: '50px'}} src={`${user.picture}`} alt={`${user?.name} profile picture`} />
        <ArrowDropDownIcon onClick={(e) => handleClick(e)} sx={{ color: 'lightgrey', margin: '0', padding: '0' }} />
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setOpen(!open)}
            disableScrollLock={true}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
        >
            {
              isAuthenticated && user["digitalbyte.io_abc"] 
              ? <MenuItem onClick={() => navigate('/dashboard')}>Dashboard</MenuItem> : null
            }
            {
              isAuthenticated ? <MenuItem onClick={() => setOpen(!open)}>My account</MenuItem> : null
            }
            <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>

        </Menu>
    </div>
    ) : <LoginButton />;
};
