import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function DashboardLayout({children}) {
    const { isAuthenticated } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate])


    return (
        <>
            {
                isAuthenticated ? <div>{children}</div> : <p>User not authenticated</p>
            }
        </>
    )
}

export default DashboardLayout