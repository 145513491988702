import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion/dist/framer-motion'
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import './index.css';
import axios from 'axios';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

function Gallery() {
    const [images, setImages] = useState([]);

    async function getImages() {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/utils/photos/enabled`)
            console.log(res.data.r)
            setImages(res.data.r)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getImages()
    }, [])

    return (
        <div id="gallery" className="gallery__container">
            <h2>Our <span className="color__blue">Location</span> Photos</h2>
            <p>This is a display of all our equpiment, and places we can service.</p>
            <div className="gallery__container__section">
                {
                    images.length > 0 ? images.map((image, index) => {
                        return (
                            <HtmlTooltip key={`item-${index}`} title={
                                <>
                                    <h5>{image.title}</h5>
                                    <p>{image.description}</p>
                                </>
                            }>
                                <motion.img 
                                    key={index}
                                    initial={{ opacity: 0}} 
                                    whileInView={{ opacity: 1 }} 
                                    transition={{ duration: 1.25 }}
                                    src={image.url} />
                            </HtmlTooltip>
                        )
                    }) : <p>No images currently uploaded.</p>
                }
            </div>
        </div>
    )
}

export default Gallery;
