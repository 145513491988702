import React, { useRef } from 'react';
import PipeSystem from '../../images/pipe_system.svg';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { motion } from 'framer-motion/dist/framer-motion'
import './index.css';

function About() {
    return (
        <div id="about" className="about__section">
            <motion.div
                initial={{ opacity: 0}} 
                whileInView={{ opacity: 1 }} 
                transition={{ duration: 1 }} 
                className="about__container">
                <div className="about__container__item">
                    <h2>Know More <span className="color__blue">About Us</span></h2>
                    <h3>We are professionals</h3>
                    <p>Our Goal was to develop a method to perform the Annual Foam Proportioning Test. A method that would be approved through NFPA AND Factory Mutual approvals that would eliminate the environmental concerns of discarding Foam or Test Liquids This would eliminate the expense of flowing and replacing foam concentrate, and remove the cost of trucking and disposal of the foam concentrate. Allowing us to flow low, midrange and high range of the proportioner.</p>
                    <ul>
                        <li><CheckCircleIcon className="circle__icon" />Professional Staff</li>
                        <li><CheckCircleIcon className="circle__icon" />Cost Effective</li>
                        <li><CheckCircleIcon className="circle__icon" />Variable Testing</li>
                        <li><CheckCircleIcon className="circle__icon" />Enviornment Friendly</li>
                        <li><CheckCircleIcon className="circle__icon" />Thorough Testing</li>
                        
                    </ul>
                </div>
                <div className="about__container__item">
                    <img className="pipe__system" src={PipeSystem} alt="Foam proportioner diagram" />
                </div>
                
            </motion.div>
        </div>
    )
}

export default About;
