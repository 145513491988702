import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [verified, setVerified] = useState({ isVerified: false });
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    

    async function verifyUser() {
        try {
            const accessToken = await getAccessTokenSilently();
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/users/verify`,  { googleId: user.sub }, {
                headers: {
                    authorization: `Bearer ${accessToken}`
            }})
            setVerified({ isVerified: res.data.verfied});
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            verifyUser()
        }
    }, [isAuthenticated])

    return (
        // Use the UserContext.Provider to pass down the user state
        <UserContext.Provider value={{ verified, setVerified }}>
            {children}
        </UserContext.Provider>
    );
};

