import React from 'react';
import GoogleMapReact from 'google-map-react';
import Pin from './pin/Pin';
import './index.css';

function Map() {
    const defaultMap = {
        center: {
          lat: 39.96,
          lng: -83.00
        },
        zoom: 11
      };
    return (
        <div>
            <div className="location__container">
                <h2>Where we're <span className="color__blue">Located</span></h2>
                <p className="location__context">Proudly serving North, and Latin America!<br />Located in Latin America? Check out our affiliates at <a className="color__blue" href="https://www.industrialservices.ec/">Industrial services!</a></p>
            </div>
            <div style={{ height: '50vh', width: '100%'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: `${process.env.REACT_APP_MAP_API_KEY}` }}
                    defaultCenter={defaultMap.center}
                    defaultZoom={defaultMap.zoom}>
                    <Pin lat={39.96}
                        lng={-83.00}
                        text="Foam Solutions"/>
                </GoogleMapReact>
            </div>
        </div>
    )
}

export default Map
