import React from 'react';
import Navigation from './components/navigation/Navigation';
import Hero from './components/hero/Hero';
import About from './components/about/About';
import './App.css'
import Testing from './components/testing/Testing';
import Gallery from './components/gallery/Gallery';
import Features from './components/features/Features';
import Map from './components/map/Map';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';

function App() {
  return (
    <div>
      <Navigation  />
      <Hero />
      <Testing />
      <About />
      <Gallery />
      <Features />
      <Map />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;