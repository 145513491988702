import React, { useEffect, useState } from 'react'
import Dashboard from '../Dashboard'
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';


export default function Forms() {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const [forms, setForms] = useState([]);

    async function getAllForms() {
        if (isAuthenticated) {
            const accessToken = await getAccessTokenSilently();

            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/utils/all`, {
                headers: {
                    authorization: `Bearer ${accessToken}`
                }
            });
            console.log(data)
            setForms(data);
        }
    }

    async function deleteForm(id) {
        if (isAuthenticated) {
            const accessToken = await getAccessTokenSilently();

            const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/utils/delete/${id}`, {
                headers: {
                    authorization: `Bearer ${accessToken}`
                }
            });
            getAllForms();
        }
    }

    function changeDate(date) {
        const dt = new Date(date);
        return dt.toLocaleDateString("en-US");
    }

    useEffect(() => {
        getAllForms();
    },[isAuthenticated]);
  return (
    <Dashboard>
        <div style={{ backgroundColor: '#202020', padding: '1rem 2rem', borderRadius: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: '1rem', width: '100%' }}>
              <h5 style={{ color: '#fff', fontWeight: 400, fontSize: '1.2rem'}}>Contact Submissions</h5>
              <div style={{ height: '1px', width: '100%', backgroundColor: 'rgb(36, 170, 238)', margin: '.5rem 0' }} />
              <p style={{ color: '#fff', fontSize: '.8rem' }}>Note: Utilize this page to view, and delete contact submissions. Once you delete they cannot be retrieved. Keep in mind these are also sent to <span style={{ color: 'lightgreen'}}>ps@fs131.com</span>.</p>
        </div>
        <div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column', gap: '.5rem'}}>
            {
                forms.length > 0 ? (forms.map((item, i) => {
                    return <div key={`item-${i}`} style={{ width: '100%', backgroundColor: '#454545', borderRadius: '8px', padding: '1rem'}}>
                        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                            <p style={{ flex: 1, color: '#fff'}}>{item.name}</p>
                            <p style={{ flex: 1, textAlign: 'center', color: '#fff'}}>{item.email}</p>
                            <p style={{ flex: 1, textAlign: 'right', color: '#fff'}}>{item.phone}</p>
                        </div>
                        <div style={{ width: '100%', height: '2px', backgroundColor: 'rgb(32, 32, 32)', marginBottom: '1rem'}} />
                        <div>
                            <p style={{ color: '#fff'}}>{item.message}</p>
                        </div>
                        <div style={{ width: '100%', height: '2px', backgroundColor: 'rgb(32, 32, 32)', marginTop: '1rem'}} />
                        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'space-between'}}>
                            <p style={{ color: '#fff'}}>{changeDate(item.createdat)}</p>
                            <Button onClick={() => deleteForm(item.id)} variant="contained" color="error">Delete</Button>
                        </div>
                    </div>
                })) : null
            }
        </div>
    </Dashboard>
  )
}
