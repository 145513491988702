import React from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import RoomIcon from '@material-ui/icons/Room';
import './index.css';

function Footer() {
    return (
        <div className="footer__section">
            <div className="footer__container">
                <div className="footer__container__item">
                    <h3 className="container__logo__header">Fs.</h3>
                    <p>Proudly serving our community for over 20+ years. Leading technology in saving the enviornment and valued structures with foam proportioner testing.</p>
                </div>
                <div className="footer__container__item">
                    <h3>Quick Link</h3>
                    <div className="footer__item__section">
                        <a href="#home">Home</a>
                        <a href="#about">About</a>
                        <a href="#services">Services</a>
                        <a href="#features">Features</a>
                        <a>NFPA Codes</a>
                        <a href="#contact">Contact</a>
                    </div>
                </div>
                <div className="footer__container__item">
                    <h3>NFPA Codes</h3>
                    <a>NFPA 11</a>
                    <a>NFPA 16</a>
                    <a>NFPA 25</a>
                    <a>NFPA 30</a>
                    <a>NFPA 409</a>
                    <a>NFPA 418</a>
                    <a>NFPA 1925</a>
                </div>
                <div className="footer__container__item">
                    <h3>Contact us</h3>
                    <div className="icon__container">
                        <MailIcon className="icon__container__icon" />
                        <p>ps@fs131.com</p>
                    </div>
                    <div className="icon__container">
                        <PhoneIcon className="icon__container__icon" />
                        <p>+833-600-3626</p>
                    </div>
                    <div className="icon__container">
                        <RoomIcon className="icon__container__icon" />
                        <p>2251 Performance Parkway<br />Columbus, OH 43207<br /></p>
                    </div>
                </div>
            </div>
            <div className="footer__pantents">
                <p>©2023 Foam Solutions, LLC FM Certified and Patented Foam Proportioner Testing</p>
                <p>U.S. Patents || International Patents || Other Patents Pending</p>
            </div>
        </div>
    )
}

export default Footer
