import React from 'react';
import { Routes, useNavigate, Route } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from '../App';
import HomeDashboard from '../pages/dashboard/Home';
import { UserProvider } from '../providers/UserProvider';
import Forms from '../pages/dashboard/Forms';
import Photos from '../pages/dashboard/Photos';
// import Pages from '../pages/dashboard/Pages';
// import EditPage from '../pages/dashboard/pages/EditPage';
import { NFPAProvider } from '../providers/NFPAProvider';
import NFPA from '../pages/NFPA';

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = `${process.env.REACT_APP_AUTH0_DOMAIN}`;
  const clientId = `${process.env.REACT_APP_AUTH0_CLIENT_ID}`;
  const history = useNavigate();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      // onRedirectCallback={onRedirectCallback}
      // useRefreshTokens={true}
      redirectUri={`${process.env.REACT_APP_AUTH0_RETURN_URL}`}
      audience={`${process.env.REACT_APP_AUTH0_API_AUDIENCE}`}
      scope={`${process.env.REACT_APP_AUTH0_API_SCOPE}`}
    >
      <UserProvider>
        <NFPAProvider>
        <Routes>
            <Route path='/' element={<App />} />
            <Route path='/dashboard' element={<HomeDashboard />} />
            <Route path='/dashboard/forms' exact element={<Forms />} />
            <Route path='/dashboard/photos' exact element={<Photos />} />
            {/* <Route path='/dashboard/pages' exact element={<Pages />} />
            <Route path='/dashboard/pages/edit' exact element={<EditPage />} /> */}
            <Route path='/nfpa/:id' exact element={<NFPA />} />
        </Routes>
        {children}
        </NFPAProvider>
      </UserProvider>
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;