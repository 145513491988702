import React, { useContext, useEffect } from 'react'
import DashboardLayout from '../components/layout/DashboardLayout'
import SettingsIcon from '@mui/icons-material/Settings';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import DescriptionIcon from '@mui/icons-material/Description';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import HomeIcon from '@mui/icons-material/Home';
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider';

function Dashboard({children}) {
    const { user } = useAuth0();
    const userState = useContext(UserContext);

    return (
        <>
            {
                user["digitalbyte.io_abc"] ? (
                    <div style={{ backgroundColor: '#121212', height: '100vh', width: '100vw'}}>
                        <div style={{ display: 'flex', height: '100vh' }}>
                            <div style={{ backgroundColor: '#202020', flex: 2, minWidth: '300px', height: '100%', position: 'relative'}}>
                                <Link to='/'>
                                    <h3 style={{ color: '#24aaee', fontWeight: 'bold', fontSize: '2.4rem', margin: '.5rem'}}>Fs.</h3>
                                </Link>
                                <div style={{ height: '1px', width: '100', backgroundColor: '#24aaee', margin: '.5rem' }} />
                                {/* UserInfo Box */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                                    <img src={user?.picture} alt={`${user?.name} profile picture`} style={{ borderRadius: '50%', height: '85px' }} />
                                    <p style={{ color: 'lightgrey'}}>{user?.name}</p>
                                </div>
                                <div style={{ height: '1px', width: '100', backgroundColor: '#24aaee', margin: '.5rem' }} />
                                <div style={{ padding: '.5rem'}}>
                                    <h6 style={{ color: 'lightgrey' }}>Menu</h6>
                                    <div style={{ marginTop: '1rem'}}>
                                        <Link to='/dashboard'>
                                            <div style={{ display: 'flex', alignItems: 'center', padding: '.75rem 1rem' }}>
                                                <HomeIcon sx={{ color: '#fff', fontSize: '2rem'}} />
                                                <p style={{ color: '#fff', marginLeft: '.5rem' }}>Home</p>
                                            </div>
                                        </Link>
                                        <Link to='/dashboard/forms'>
                                            <div style={{ display: 'flex', alignItems: 'center', padding: '.75rem 1rem' }}>
                                                <ContactPhoneIcon sx={{ color: '#fff', fontSize: '2rem'}} />
                                                <p style={{ color: '#fff', marginLeft: '.5rem' }}>Form Submissions</p>
                                            </div>
                                        </Link>
                                        <Link to='/dashboard/pages'>
                                            <div style={{ display: 'flex', alignItems: 'center', padding: '.75rem 1rem' }}>
                                                <DescriptionIcon sx={{ color: '#fff', fontSize: '2rem'}} />
                                                <p style={{ color: '#fff', marginLeft: '.5rem' }}>NFPA Pages</p>
                                            </div>
                                        </Link>
                                        <Link to='/dashboard/photos'>
                                            <div style={{ display: 'flex', alignItems: 'center', padding: '.75rem 1rem' }}>
                                                <PhotoLibraryIcon sx={{ color: '#fff', fontSize: '2rem'}} />
                                                <p style={{ color: '#fff', marginLeft: '.5rem' }}>Photos</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <p style={{ fontSize: '12px', position: 'absolute', bottom: 0, color: '#545353', left: '75px' }}>Created by: Digitalbyte.io</p>
                            </div>
                            <div style={{ flex: 10, padding: '1rem', height: '100vh', overflowY: 'scroll' }}>
                                <div style={{ backgroundColor: '#202020', padding: '1rem 2rem', borderRadius: '8px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <p style={{ color: '#fff' }}>{`Hello, ${user?.name}!`}</p>
                                    <SettingsIcon sx={{ color: 'lightgrey' }} />
                                </div>
                                {children}
                            </div>
                        </div>
                    </div>
                ) : (
                    <p>You are unauthorized.</p>
                )
            }
        </>
    )
}

export default Dashboard