import React, { useEffect, useState } from 'react'
import Navigation from '../components/navigation/Navigation'
import Footer from '../components/footer/Footer'
import { useParams } from 'react-router-dom'
import axios from 'axios'
export default function NFPA() {
    const { id } = useParams()
    const [data, setData] = useState(null)

    async function getPage() {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/utils/pages/${id}`)
        console.log('PAGE DATA: ', res.data.r)
        setData(res.data.r);
    }

    useEffect(() => {
        getPage();
    }, [id])

    return (
        <>
            <Navigation />
            {/* {
                data !== null ? <div><div style={{ backgroundImage: 'url(\'https://i.ibb.co/n8hnR8c/background.jpg\')', width: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '400px', boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)'
                    }}>
                        <h2 style={{ textAlign: 'center', color: '#fff', paddingTop: '10rem' }}>{data.title}</h2>
                    </div>
                    <div>
                        <div style={{ width: '100%', backgroundColor: '#fff' }}>
                            <div style={{ backgroundColor: '#f2f2f2', paddingTop: '2rem', width: '800px', margin: '2rem auto'}}>
                                <EditorReadOnly data={data.content} editorblock="editorjs-container" />
                            </div>
                        </div>
                    </div>
                </div> : <p>Loading...</p>
            } */}
            <Footer />
        </>
    )
}
