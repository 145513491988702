import React from 'react';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { motion } from 'framer-motion/dist/framer-motion'
import './index.css';

function Testing() {
    return (
        <div id="services" className="testing__container">
            <img src="./images/industrial-upipe-svgrepo-com.svg" style={{ height: '800px', width: '700px', position: 'absolute', bottom: '-110px', zIndex: '-1'}} />
            <h2>Our <span className="color__blue">Services</span></h2>
            <p style={{ marginTop: 0 }} className="tagline">We pride ourselves in being able to test the three main types of foam with water! Saving time, money, and the enviornment.</p>
            <div className="testing__container__section">
                <motion.div 
                    initial={{ opacity: 0}} 
                    whileInView={{ opacity: 1 }} 
                    transition={{ duration: .75 }} 
                    className="testing__container__section__item">
                    <BookmarkIcon className="testing__container__icon"/>
                    <h3>HEF</h3>
                    <p>High Expansion Foam is most commonly used in aircraft hangars with its ability to expand quickly and cover a large amount of space.</p>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0}} 
                    whileInView={{ opacity: 1 }} 
                    transition={{ duration: 1 }}  
                    className="testing__container__section__item">
                    <BookmarkIcon className="testing__container__icon"/>
                    <h3>AR</h3>
                    <p>Alcohol Resistent foam concentrates provide superior fire and vapor supression. Most commonly used for Class B, Polar Solvent and Hydocarbon Fuel fires.</p>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0}} 
                    whileInView={{ opacity: 1 }} 
                    transition={{ duration: 1.25 }} 
                    className="testing__container__section__item">
                    <BookmarkIcon className="testing__container__icon"/>
                    <h3>AFFF</h3>
                    <p>Aqueous Film Forming Concentrates forms an aqueous film on the surfce emliminating hydocarbon fuels.</p>
                </motion.div>
            </div>
        </div>
    )
}

export default Testing;
