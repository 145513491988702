import React, { useState, useEffect, useRef } from 'react'
import Dashboard from '../Dashboard'
import { Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import LinkIcon from '@mui/icons-material/Link';
import axios from 'axios';

export default function Photos() {
    const cloudinaryRef = useRef();
    const widgetRef = useRef();
    const [loadedPhotos, setLoadedPhotos] = useState([]);
    const [photos, setPhotos] = useState('upload');
    const [btnText, setBtnText] = useState('Upload');
    const [imgInfo, setImgInfo] = useState(null);
    const [data, setData] = useState({ title: '', description: '', fileUrl: ''});
    const { getAccessTokenSilently } = useAuth0();

    async function getAllPhotos() {
        const accessToken = await getAccessTokenSilently();
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/utils/photos`, {
                headers: { authorization: `Bearer ${accessToken}` }
            })
            setLoadedPhotos(res.data);
        } catch (err) {
            console.log(err);
        }
    }

    async function changeEnabled(id, enabled) {
        const accessToken = await getAccessTokenSilently();
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/utils/photo`, { id, enabled }, {
                headers: { authorization: `Bearer ${accessToken}` }
            })
            getAllPhotos();
        } catch (err) {
            console.log(err);
        }
    }

    async function deletePhoto(id) {
        const accessToken = await getAccessTokenSilently();
        try {
            const res = await axios.delete(`${process.env.REACT_APP_API_URL}/utils/photo/${id}`, {
                headers: { authorization: `Bearer ${accessToken}` }
            })
            getAllPhotos();
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        cloudinaryRef.current = window.cloudinary;
        widgetRef.current = cloudinaryRef.current.createUploadWidget({
            cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
            uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
            sources: ['local', 'url', 'camera', 'facebook', 'instagram', 'dropbox', 'google_photos']
        }, function (error, result) {
            if (!error && result && result.event === "success") {
                console.log('Done! Here is the image info: ', result.info);
                setImgInfo({...imgInfo, url: result.info.url, originalFilename: result.info.original_filename, fileType: result.info.format});
                setData({...data, fileUrl: result.info.url})
            }
        });
    }, []);

    useEffect(() => {
        getAllPhotos();
    }, [])

    async function submitForm(e) {
        e.preventDefault();
        const accessToken = await getAccessTokenSilently();
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/utils/upload`, {
                method: 'POST',
                headers: { "Content-Type": "application/json", authorization: `Bearer ${accessToken}` },
                body: JSON.stringify(data),
            });
            setData({ title: '', description: '', fileUrl: ''});
            setImgInfo(null);
            if (res.status === 200) {
                setTimeout(() => {
                    setBtnText('Submitted!');
                }, 4000);   
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Dashboard>
            <div style={{ backgroundColor: '#202020', padding: '1rem 2rem', borderRadius: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: '1rem', width: '100%' }}>
                <h5 style={{ color: '#fff', fontWeight: 400, fontSize: '1.2rem'}}>Photo Management Panel</h5>
                <div style={{ height: '1px', width: '100%', backgroundColor: 'rgb(36, 170, 238)', margin: '.5rem 0' }} />
                <p style={{ color: '#fff', fontSize: '.8rem' }}>Note: This panel allows you to upload, and set photos for portions of the website.</p>
            </div>
            <div style={{ backgroundColor: '#202020', width: '100%', marginTop: '1rem', padding: '1rem 2rem', borderRadius: '8px' }}>
                <Button onClick={() => setPhotos('upload')} variant={photos === 'upload' ? 'contained' : 'outlined' } color="primary">Upload Photo</Button>
                <Button onClick={() => setPhotos('set')} variant={photos === 'set' ? 'contained' : 'outlined' }  color="primary" style={{ marginLeft: '1rem'}}>Set Photos</Button>
                {
                    photos === 'upload' ? (
                        <div style={{ padding: '1rem 0'}}>
                            <form style={{ color: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center'}} onSubmit={(e) => submitForm(e)}>
                                <Button sx={{ marginTop: '1rem' }} onClick={() => widgetRef.current.open()}>Choose File</Button>
                                    {
                                        imgInfo !== null ? (
                                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                <img style={{ height: '100px', width: '100px' }} src={imgInfo.url} alt={imgInfo.original_filename} />
                                                <p style={{ marginTop: '1rem' }}>File name: {imgInfo.originalFilename}.{imgInfo.fileType}</p>
                                            </div>
                                        ) : null
                                    }
                                <input name='title' required value={data.title} onChange={(e) => setData({...data, title: e.target.value})} placeholder='Image title goes here...' style={{ padding: '.5rem', fontSize: '1.2rem', color: '#fff', backgroundColor: 'transparent', borderBottom: '1px solid rgb(36, 170, 238)', width: '100%'}} />
                                <textarea name='description' required value={data.description} onChange={(e) => setData({...data, description: e.target.value})} placeholder='Image description goes here...' style={{ fontFamily: 'inherit', fontSize: '1.2rem', padding: '.5rem', marginTop: '1rem', backgroundColor: 'transparent', borderBottom: '1px solid rgb(36, 170, 238)', color: '#fff' }} rows={7}/>
                                <Button disabled={ data.fileUrl === '' ? true : false} type='submit' variant='contained' color='primary' style={{ marginTop: '1rem', width: '100%' }}>{btnText}</Button>
                            </form>
                        </div>
                    ) : (
                        <div style={{ padding: '1rem 0'}}>
                            {
                                loadedPhotos.length > 0 ? (
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                        {
                                            loadedPhotos.map((photo, i) => (
                                                <div key={i} style={{ display: 'flex', backgroundColor: '#131313', padding: '1rem', borderRadius: '8px', justifyContent: 'space-between' }}>
                                                    <div style={{ display: 'flex'}}>
                                                        <img style={{ height: '50px', width: '50px', borderRadius: '8px' }} src={photo.url} alt={photo.title} />
                                                        <p style={{ marginTop: '1rem', color: '#fff', paddingLeft: '1rem' }}>{photo.title}</p>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Tooltip title={photo.enabled ? 'Disable Photo' : 'Enable Photo'}>
                                                            <Switch defaultChecked={photo.enabled} onChange={() => changeEnabled(photo.id, photo.enabled)} />
                                                        </Tooltip>
                                                        <Tooltip title='View Photo' placement='top'>
                                                            <LinkIcon onClick={() => window.open(photo.url, '_blank')} sx={{ color: 'lightgrey', marginLeft: '.5rem' }} style={{ cursor: 'pointer' }} />
                                                        </Tooltip>
                                                        <Tooltip title='Delete Photo' placement='top'>
                                                            <DeleteIcon onClick={() => deletePhoto((photo.id))} sx={{ color: 'lightgrey', marginLeft: '.5rem'}} style={{ cursor: 'pointer' }} />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <p style={{ color: '#fff', fontSize: '1.2rem' }}>No photos found.</p>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
        </Dashboard>
    )
}
